import React from "react";
import "./footer.scss";
import { NavLink } from "react-router-dom";
import { Fade, Zoom } from "react-reveal";
import moment from "moment";
import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa6";
import { BsTwitterX } from "react-icons/bs";
export default function Footer() {
  const socialMedia = [
    {
      title: "Facebook",
      icon: <FaFacebookF />,
      link: "https://www.facebook.com/",
    },
    {
      title: "Twitter",
      icon: <BsTwitterX />,
      link: "https://x.com/?lang=en",
    },
    {
      title: "Instagram",
      icon: <FaInstagram />,
      link: "https://www.instagram.com/",
    },
    {
      title: "Linkdin",
      icon: <FaLinkedin />,
      link: "https://in.linkedin.com/",
    },
  ];
  return (
    <footer>
      <div className="footer-background">
        <div className="container">
          <div className="footer-heading">
            <Fade bottom duration={600} delay={400}>
              <h6 className="head" title="Get To Know Us!">
                Get To Know Us!
              </h6>
            </Fade>
            <Fade bottom duration={700} delay={500}>
              <p
                title="Explore who we are, our mission, and values. Learn about our
                team's expertise, commitment to innovation, and dedication to
                customer satisfaction."
              >
                Explore who we are, our mission, and values. Learn about our
                team's expertise, commitment to innovation, and dedication to
                customer satisfaction.
              </p>
            </Fade>
          </div>
          <div className="footer-main-grid">
            <div className="sub-grid-info">
              <div className="main-contact-info">
                <div className="sub-contact">
                  <Fade bottom>
                    <p title="Email: vedhasaitech@gmail.com">
                      Email: vedhasaitech@gmail.com
                    </p>
                  </Fade>
                  <Fade bottom>
                    <p title="India: +91 88668 61316">India: +91 88668 61316</p>
                  </Fade>
                  <Fade bottom>
                    <p
                      title="Address: 223, Atlanta Shopping Mall, Beside Abhishek-3,
                      Mota Varachha Road, Surat City, Surat- 395006, Gujarat."
                    >
                      Address: 223, Atlanta Shopping Mall, Beside Abhishek-3,
                      Mota Varachha Road, Surat City, Surat- 395006, Gujarat.
                    </p>
                  </Fade>
                </div>
                <Fade bottom duration={700} delay={500}>
                  <div className="social-media">
                    {socialMedia?.map((el, index) => {
                      return (
                        <NavLink
                          target="_blank"
                          to={el?.link}
                          key={index}
                          title={el?.title}
                          className="socialMedia"
                        >
                          {el?.icon}
                        </NavLink>
                      );
                    })}
                  </div>
                </Fade>
              </div>
            </div>
            <div className="sub-grid-info">
              <div className="main-contact-info2">
                <Fade bottom>
                  <div className="sub-contact">
                    <NavLink to={"/blog"} title="Insights">
                      Insights
                    </NavLink>
                    <NavLink to={"/services"} title="Our Expertise">
                      Our Expertise
                    </NavLink>
                    <NavLink to={"/disclaimer"} title="Disclaimer">
                      Disclaimer
                    </NavLink>
                    <NavLink target="_blank" to={`https://wa.me/8866861316?text=Hello%20there!`}>
                      Work with us
                    </NavLink>
                  </div>
                </Fade>
                <Fade bottom>
                  <div className="sub-contact">
                    <NavLink to={"/"} title="Home">
                      Home
                    </NavLink>
                    <NavLink to={"/product"} title="Our Work">
                      Our Work
                    </NavLink>
                    <NavLink to={"/contact"} title="Contact Us">
                      Contact Us
                    </NavLink>
                    <NavLink to={"/about"} title="Know us">
                      Know us
                    </NavLink>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
          <div className="copy-right">
            <div className="Legal_Links">
              <NavLink to={"/privacy-policy"} title="Privacy Policy">
                Privacy Policy
              </NavLink>
              <NavLink to={"/terms-and-conditions"} title="Terms & Conditions">
                Terms & Conditions
              </NavLink>
            </div>
            <Zoom>
              <p
                title={`© Copyright ${moment().year()} - VEDHAS AI TECHNOLOGIES PRIVATE LIMITED`}
              >
                © Copyright {moment().year()} - VEDHAS AI TECHNOLOGIES PRIVATE
                LIMITED
              </p>
            </Zoom>
          </div>
        </div>
      </div>
    </footer>
  );
}
