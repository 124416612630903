import React from "react";
import "./finestwork.scss";
import Frame from "../../../assets/images/Frame 101.svg";
import Frame1 from "../../../assets/images/Frame 101 (1).svg";
import Frame2 from "../../../assets/images/Frame 101 (2).svg";
import { Fade, Zoom } from "react-reveal";
import { NavLink } from "react-router-dom";
export default function Finestwork() {
  const Finest_work = [
    {
      image: Frame1,
      heading: "AssignmentGPT AI",
      Link: "AssignmentGPT AI",
      url: "https://assignmentgpt.ai",
      description:
        ", where academic excellence meets the latest in technology! Our AI-powered platform is revolutionizing homework assistance by offering personalized support tailored to students of all levels. With features such as instant feedback, step-by-step solutions, and adaptive learning experiences, we ensure that every student receives the guidance they need to succeed. Backed by a team of experienced educators and AI specialists, our commitment is to empower students to conquer their academic challenges and reach their full potential. Whether it's math problems, science concepts, essay writing, or exam preparation, our intuitive platform and interactive tools are available 24/7 to provide comprehensive support. Join us today for a free trial and witness the future of homework help with AI Homework Helper!",
    },
    {
      image: Frame,
      heading: "AI Gym",
      Link: "AI Gym",
      url: "https://play.google.com/store/apps/details?id=com.fitever.fitness.workout.diet.planner",
      description:
        ",  your ultimate fitness companion powered by artificial intelligence! Revolutionize your workout routine with our cutting-edge platform, which offers personalized training programs, real-time feedback, and intelligent coaching to help you reach your fitness goals faster. Whether you're a beginner looking to get started on your fitness journey or a seasoned athlete striving for peak performance, AI Gym adapts to your needs and preferences, providing tailored workouts and insights based on your progress and feedback. With an extensive library of exercises, virtual trainers, and interactive challenges, staying motivated and making progress has never been easier. Join AI Gym today and unlock your full potential with the power of AI!",
    },
    {
      image: Frame2,
      heading: "Botify Me",
      description:
        "Botifyme is a powerful, all-in-one chatbot solution designed to adapt to your specific goals. Whether you need a sales bot that actively engages customers, answers queries, and drives conversions, or an educational bot that offers in-depth information and fosters learning, Botifyme delivers exceptional versatility. Its advanced knowledge management system allows your bot to store, organize, and retrieve information efficiently, ensuring that it's always equipped to provide the right response at the right time. Botifyme’s customization options enable you to tailor it to your brand’s tone and style, ensuring a seamless interaction that reflects your business or educational objectives. Whether it’s automating customer service, generating leads, or delivering personalized learning experiences, Botifyme empowers you to transform interactions and enhance user engagement effortlessly.",
    },
  ];
  return (
    <section className="finest_work_section">
      <div className="container">
        <div className="finest_work_heading">
          <Fade bottom duration={600} delay={400}>
            <h3
              className="heading"
              title="Exploring Our Innovative Products and Pioneering Work with Cutting-Edge Technology"
            >
              Exploring Our <span>Innovative Products</span> and Pioneering Work
              with Cutting-Edge <span> Technology</span>
            </h3>
          </Fade>
        </div>
        {Finest_work &&
          Finest_work?.map((el, i) => {
            return (
              <div className="main-flex" key={i}>
                <div className="finest-image">
                  <Zoom>
                    <img src={el?.image} title={el?.heading} alt={el?.heading} />
                  </Zoom>
                </div>
                <div className="text-work" title={el?.heading}>
                  <Fade bottom duration={700} delay={500}>
                    <h2>{el?.heading}</h2>
                  </Fade>
                  <Fade bottom duration={800} delay={600}>
                    <span>
                      {el?.Link && (
                        <a href={el?.url} target="_blank" rel="noreferrer">
                          {el?.Link}
                        </a>
                      )}
                    </span>
                    <span>{el?.description}</span>
                  </Fade>
                  <Fade bottom duration={800} delay={600}>
                    <NavLink target="_blank" href={el?.url}>
                      <button>Get In Touch</button>
                    </NavLink>
                  </Fade>
                </div>
              </div>
            );
          })}
      </div>
    </section>
  );
}
